@import 'styles/imports';


.arrow {
    margin-left: 5px;
    transition: 0.2s;
    transform: rotate(0deg);
}
.arrow.flip {
    transform: rotate(180deg);
    transition: 0.2s;
}

.index-collapse {
    background-color: rgb(253, 125, 78);
    color: white;
    padding: 10px 10px;
    border-radius: 10px;
    margin-top: 10px;
    padding-left: 15px;
    font-size: 16px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    position: relative;
    cursor: pointer;
}

.index {
    font-size: 18px;
    ul{
        list-style: disc;
    }

    .index-title{
        font-weight: bold;
        list-style: upper-roman;
    }

    .link {
        color: rgb(0, 0, 186);
        cursor: pointer;
        width: fit-content;
    }
}

