@import 'styles/imports';

.code-sample-title {
    border: 1px solid gray;
    padding: 5px;
    padding-left: 10px;
    font-weight: bold;
    margin-bottom: 3px;
    margin-top: 15px;
}

.component-title-header {
    display: flex;
    align-items: center;
}

.extends {
    margin-left: 10px;
    font-size: 18px;
    font-weight: normal;
}