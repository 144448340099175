@import '../../styles/imports';
$current:$mobile;

.form-page-container {
    width: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: fit-content;
    background-color: #e7e8e4;
    padding: vw_size(15,$current);
    margin: auto;
    border: 1px solid black;

    .title {
        color: black;
        text-align: center;
    }
}
@media (min-width: $media-tablet) {
    $current:$tablet;

    .form-page-container {
        padding: vw_size(15,$current);
    }
}
@media (min-width: $media-desktop) {
    $current:$desktop;

    .form-page-container {
        padding: vw_size(15,$current);
    }
}
@media (min-width: $media-desktop-large) {
    $current:$desktop_large;

    .form-page-container {
        padding: vw_size(15,$current);
    }
}