/* colors */
$background-color: #f3f8fd;
$background-color: #FFFFFF;
$popup-background:#e8dfbc;
$loader-color:#000000;
$text-color:#000000;
$grey-divider: #e7e8e4;
$dark-grey: #565555;
$text-black: #0c0803;
$grey:#9fa0a4;

$react-teal:#61DAFB;
$orange:#ff4500;

/* break points */
$mobile: 320;
$tablet: 768;
$desktop: 1200;
$desktop_large: 1500;
$desktop-max: 1920;

/* media queries */
$media-tablet: $tablet + px;
$media-desktop: $desktop + px;
$media-desktop_large: $desktop_large + px;
$media-desktop-max: $desktop-max + px;