@import '../../../styles/imports';
$current:$mobile;

.payment-form-wrapper {
  padding:vw_size(30, $current) vw_size(15, $current);
  color: black;

  .field {
    margin-bottom: vw_size(35,$current);
  }
  .submit-btn {
    width: 100%;
    height:vw_size(30,$current);
  }

}

@media (min-width: $media-tablet) {
  $current:$tablet;

  .payment-form-wrapper {
    width:vw_size(400,$current);
    margin: 0 auto;
    padding:vw_size(70, $current) vw_size(15, $current);

    .field {
      margin-bottom: vw_size(35,$current);
    }
    .submit-btn {
      height:vw_size(30,$current);
    }
  }
}
@media (min-width: $media-desktop) {
  $current:$desktop;

  .payment-form-wrapper {
    width:vw_size(300,$current);
    padding:vw_size(70, $current) vw_size(15, $current);

    .field {
      margin-bottom: vw_size(35,$current);
    }

    .submit-btn {
      height:vw_size(30,$current);
    }
  }
}
@media (min-width: $media-desktop-large) {
  $current:$desktop_large;

  .payment-form-wrapper {
    width:vw_size(300,$current);
    padding:vw_size(70, $current) vw_size(15, $current);

    .field {
      margin-bottom: vw_size(35,$current);
    }
    .submit-btn {
      height:vw_size(30,$current);
    }
  }
}