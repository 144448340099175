@import 'styles/imports';
.code-sample {
    td, th{
        text-align: left;
        padding: 10px;
        border: 1px solid black;
    }

    th{
        font-size: 22px;
    }

    td {
        font-size: 20px;
        vertical-align: top;
        max-width: 400px;
    }

    td:first-child {
        max-width: 50%;
    }

    thead {
        background-color: #acacac;
        color: white;
    }
}

.code-sample-title {
    border: 1px solid gray;
    padding: 5px;
    padding-left: 10px;
    font-weight: bold;
    margin-bottom: 3px;
    margin-top: 15px;
}