@import 'src/styles/imports';

.docs-tools-burger-menu {
    .right {
        background-color: #e6e6e6d6;
        height: 100%;
        justify-content: flex-end;
    }

    .burger-icon {
        width: 50px;
        float: right;
        margin: 20px
    }

    .burger-menu {
        width: 440px;

        .burger-menu-link {
            font-size: 50px;
            height: 160px;
        }
    }
}