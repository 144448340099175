@import '../../styles/imports';

.header-wrapper {
    height: vw_size(50, $mobile);


    .header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 vw_size(15, $mobile);
        height: vw_size(50, $mobile);
        z-index: 1000;
        background-color: $dark-grey;
        color: #ffffff;

        .logo {
            width: vw_size(40, $mobile);
            animation: rotation 2s infinite linear;
        }
    }

    .nav-container {
        display: flex;
    }

    .burger-icon {
        width: vw_size(20, $mobile);
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(359deg);
        }
    }
}

@media (min-width: $media-tablet) {
    .header-wrapper {
        height: vw_size(60, $tablet);

        .header {
            height: vw_size(60, $tablet);
            padding: 0 vw_size(15, $tablet);

            .logo {
                width: vw_size(60, $tablet);
            }
        }
    }
}

@media (min-width: $media-desktop) {
    .header-wrapper {
        height: vw_size(50, $desktop);

        .header {
            height: vw_size(60, $desktop);
            padding: 0 vw_size(15, $desktop);

            .logo {
                width: vw_size(60, $desktop);
            }
        }
    }
}

@media (min-width: $media-desktop-large) {
    .header-wrapper {
        height: vw_size(50, $desktop-large);

        .header {
            height: vw_size(60, $desktop-large);
            padding: 0 vw_size(15, $desktop-large);

            .logo {
                width: vw_size(60, $desktop-large);
            }
        }
    }
}