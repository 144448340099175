@import 'src/styles/imports.scss';
$current: $mobile;
.protected-route-example {
  padding: vw_size(15, $current);
}

@media (min-width: $media-tablet) {
  $current: $tablet;
  .protected-route-example {
    padding: vw_size(15, $current);
  }
}
@media (min-width: $media-desktop) {
  $current: $desktop;
  .protected-route-example {
    padding: vw_size(15, $current);
  }
}
@media (min-width: $media-desktop_large) {
  $current: $desktop_large;
  .protected-route-example {
    padding: vw_size(15, $current);
  }
}
@media (min-width: $media-desktop-max) {
  $current: $desktop-max;
  .protected-route-example {
    padding: vw_size(15, $current);
  }
}