@import '../../../styles/imports';

.burger-menu-wrapper {
    position: fixed;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    background: transparent;
    pointer-events: none;
    transition: background 300ms ease-out;

    &.active {
        pointer-events: all;
        background: rgba(0,0,0,0.5);

        .burger-menu {
            right:0;
        }
    }

    .burger-menu {
        position: absolute;
        top:0;
        width: vw_size(270,$mobile);
        height: 100vh;
        overflow-y: auto;
        right:- vw_size(270,$mobile);
        transition: right 300ms ease-out;
        background: white;
        z-index: 5;
        color: #2e2e2e;

        .burger-menu-list {
            padding:0 vw_size(10,$mobile);
            margin:0;
        }

        .burger-menu-item {
            border-bottom: 1px solid #ccc;
        }

        .burger-menu-link {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: vw_size(16,$mobile);
            text-align: right;
            height:vw_size(50,$mobile);

            &.active {
                color:#ffa600;
            }
            &:last-child {
                border:0;
            }
        }
    }
}