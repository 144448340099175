@import 'src/styles/imports';

.docs-tools-desktop-menu{
    .desktop-menu{
        justify-content: center;

        .desktop-menu-item{
            margin-inline-end: 20px;
        }
    }
}