.method-container {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 20px;
    margin-top: 20px;

    code{
        background-color: rgb(230, 230, 230);
        padding: 1px 5px;
        border-radius: 5px;
        color: black;
    }

    .method-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        .method-title {
            font-weight: bold;
            margin-right: 10px;
        }
    
        .method-type {
            width: fit-content;
            border-radius: 5px;
            font-size: 11px;
            padding: 1px 5px;
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            
            &.private{
                background-color: red;
            }
    
            &.protected {
                background-color: green;
            }
        }
    }
    
    .method-description{
        font-size: 16px;

    }

    .args-title {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        border-bottom: 1px solid black;
    }

    .args-table {

        
        td:first-child {
            font-weight: bold;
        }
        
        td{
            font-size: 14px;
            border: none;
            max-width: 50%;
            padding: 10px;
        }
    }
}